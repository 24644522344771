import { SVGAttributes } from 'react';

export default function ApplicationLogo(props: SVGAttributes<SVGElement>) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <linearGradient
                id="grad1"
                gradientUnits="userSpaceOnUse"
                x1="1"
                y1="256"
                x2="511"
                y2="256"
            >
                <stop offset="0" stopColor="#FF8619" />
                <stop offset="0.9715" stopColor="#FF0968" />
            </linearGradient>
            <path
                fill="url(#grad1)"
                d="M410.5,154v204L511,256L410.5,154z M307,154c-56.3,0-102,45.7-102,102s45.7,102,102,102h1.5L409,256L308.5,154 H307z M103,154C46.7,154,1,199.7,1,256s45.7,102,102,102c56.3,0,102-45.7,102-102S159.3,154,103,154z"
            />
        </svg>
    );
}
