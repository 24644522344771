import clsx from 'clsx';

export default function Testimonial({
    className,
    feedback,
    user,
}: {
    className: string;
    feedback: string;
    user: {
        name: string;
        role?: string;
        image?: {
            path: string;
            alt: string;
        };
    };
}) {
    return (
        <div
            className={clsx(
                className,
                'px-6 xl:px-8 lg:py-8 xl:py-10 bg-zinc-950 bg-opacity-10 rounded-lg shadow-sm backdrop-blur-sm border-opacity-30 border-solid border border-white'
            )}
        >
            <p className="text-mw-white font-medium text-lg text-end pb-5 border-b border-white whitespace-pre-line">
                “{feedback}”
            </p>

            <div className="flex items-center gap-4 xl:gap-6 pt-5 justify-end">
                <div className="text-end">
                    <p className="text-mw-white text-lg font-bold">
                        {user.name},
                    </p>
                    {user.role && (
                        <p className="text-mw-white text-sm xl:text-base font-medium mt-1.5">
                            {user.role}
                        </p>
                    )}
                </div>

                {user.image && (
                    <div className="flex-shrink-0">
                        <div className="relative rounded-full bg-gradient-to-r from-mw-orange to-mw-magenta size-20 xl:size-24">
                            <img
                                src={user.image.path}
                                alt={user.image.alt}
                                width="100"
                                height="100"
                                className="hidden lg:block absolute inset-0 size-full object-cover rounded-full"
                                loading="lazy"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
